import React from 'react'
import styled from "@emotion/styled"
import { InstagramLogo, LinkedinLogo } from "phosphor-react";

const Footerwrap = styled.footer`
letter-spacing: .75px;
font-size: .8125rem;
line-height: 1.66em;
color: rgba(0,0,0,0.5);
font-family: "Manrope";
padding: 5vh 4vw;
background: #fff;
border-top: solid 1px rgba(0,0,0,0.05);
display: grid;
grid-template-columns: repeat(4, 18.5vw);
grid-column-gap: 6vw;
@media (max-width: 599px) {
    display: block;
    padding-bottom: 0;
    font-size: .75rem;
    div {
        margin-bottom: 16px;
        &:first-of-type {
            float: right;
        }
    }
}
h6 {
    font-weight: 800;
}
a {
    color: rgba(0,0,0,0.75);
    text-decoration: none;
    border-bottom: 1px solid transparent;
    transition: all 0.3s ease;
    &:hover {
      color: rgb(0,0,0);
      border-bottom: 1px solid rgba(0, 0, 0, 0.66);
    }
  }
`
const Social = styled.div`
text-align: right;
@media (max-width: 599px) {
    text-align: left;
}
li {
    display: inline-block;
    padding-left: 7px;
    @media (max-width: 599px) {
        padding-left: 0;
        padding-right: 7px;
    }
}
a {
    color: rgba(0,0,0,0.4);
    transition: all 0.3s ease;
    &:hover {
        color: rgba(0,0,0,0.8);
    }
}
`

const Footer = () => {
    const current = new Date()
    const date = current.getFullYear()
        return (
        <Footerwrap>
            <div>copyright {date}</div>
            <div>
                <h6>Richmond</h6>
                <p>315 West Broad Street<br />Suite 400<br />
                Richmond, Virginia 23220<br />
                <a href="tel:804-308-8007">804-308-8007</a>
                </p>
            </div>
            <div>
                <h6>Brooklyn</h6>
                <p>20 Jay Street, Suite 906<br />
                Brooklyn, New York 11201<br />
                <a href="tel:646-462-6376">646-462-6376</a>
                </p>
            </div>
            <Social>
                <ul>
                <li><a href="http://instagram.com/architecturefirm" target="_blank" rel="noreferrer" role="button" aria-label="Instagram"><InstagramLogo size={36} weight="thin"/></a></li>
                <li><a href="https://www.linkedin.com/company/architecturefirm" target="_blank" rel="noreferrer" role="button" aria-label="Instagram"><LinkedinLogo size={36} weight="thin"/></a></li>
                </ul>
            </Social>
        </Footerwrap>
    )
}

export default Footer
